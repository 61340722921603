import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.array.flat-map.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import SearchResultCardProperty from "~/components/explore/SearchResultCardProperty.vue";
import SearchResultTableCellProperty from "~/components/explore/SearchResultTableCellProperty.vue";
import AddToListDialog from "~/components/list/AddToListDialog.vue";
import ExploreMapLayer from "~/components/map/layers/ExploreMapLayer.vue";
import Explore from "~/graphql/Explore/Explore.gql";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import { trackPersonLookup } from "~/helpers/tracking-helpers/person-tracking-helpers.js";
import { copySearchResultsToClipboard } from "~/helpers/clipboard-helpers.js";
export default {
  name: "Explore",
  components: {
    SearchResultCardProperty: SearchResultCardProperty,
    SearchResultTableCellProperty: SearchResultTableCellProperty,
    AddToListDialog: AddToListDialog,
    ExploreMapLayer: ExploreMapLayer
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.$store.commit("explore/setHasSearchedYet", false); // Morten; quickfix for explore not loading after navigating back
    });
  },
  layout: "default",
  title: function title() {
    return "Explore";
  },
  data: function data() {
    return {
      resultsDisplayMode: "cards",
      resultsPerPage: 25,
      pageChangeDebounce: null,
      pageChangeDebounceTime: 600,
      showAddToList: false,
      propertiesToSave: [],
      keyFigureLabels: [{
        datasetKey: "usage",
        translation: this.$t("EXPLAINER_ITEM_TITLE_USSAGE_TYPE")
      }, {
        datasetKey: "buildingCount",
        translation: this.$t("EXPLORE_LIST_ITEM_BUILDING_COUNT")
      }, {
        datasetKey: "unitCount",
        translation: this.$t("PLOT_TABLE_NO_OF_UNITS")
      }, {
        datasetKey: "totalBuildingArea",
        translation: this.$t("EXPLAINER_ITEM_TITLE_BUILDING_AREA")
      }, {
        datasetKey: "totalSiteArea",
        translation: this.$t("LIST_EXPORT_SITEAREA")
      }, {
        datasetKey: "latestPurchaseDate",
        translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LATEST_PURCHASE_DATE")
      }, {
        datasetKey: "latestPurchaseSum",
        translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LATEST_PURCHASE_SUM")
      }]
    };
  },
  apollo: {
    results: {
      query: Explore,
      variables: function variables() {
        return {
          filter: this.queryFilters
        };
      },
      update: function update(data) {
        this.$store.commit("explore/addResult", data.explore);
        this.$store.commit("explore/setLoadingState", false);
        this.$store.commit("explore/setHasSearchedYet", true);
        return data.explore;
      },
      skip: function skip() {
        return this.$store.getters["explore/getHasSearchedYet"];
      }
    }
  },
  computed: {
    exploreProperties: function exploreProperties() {
      var _this$results,
        _this = this;
      return this.results ? (_this$results = this.results) === null || _this$results === void 0 || (_this$results = _this$results.hits) === null || _this$results === void 0 || (_this$results = _this$results.filter(function (h) {
        return h.property != null;
      })) === null || _this$results === void 0 ? void 0 : _this$results.map(function (h) {
        var _p$valuations, _p$valuations$;
        var p = h.property;
        return {
          bfeNumber: h.bfeNumber,
          isDividedInCondos: p.isDividedInCondos,
          isPropertyOnList: p.isPropertyOnList,
          condo: p.condo,
          plots: p.plots,
          address: _this.getAddress(p) ? _this.$options.filters.addressLong(_this.getAddress(p)) : _this.$t("NO_ACCESS_ADDRESS"),
          owners: p.owners || [],
          administrators: p.administrators || [],
          keyFigures: {
            bfeNumber: p.bfeNumber,
            usage: ((_p$valuations = p.valuations) === null || _p$valuations === void 0 ? void 0 : _p$valuations.length) > 0 && ((_p$valuations$ = p.valuations[0]) === null || _p$valuations$ === void 0 ? void 0 : _p$valuations$.usageText) || _this.$t("VALUE_NOT_REGISTERED"),
            totalSiteArea: _this.$options.filters.kvm(_this.getTotalArea(p)),
            totalBuildingArea: _this.$options.filters.kvm(_this.getTotalBuildingArea(p)),
            buildingCount: _this.getBuildingCount(p),
            unitCount: _this.getUnitCount(p),
            latestPurchaseSum: _this.$options.filters.currency(_this.getLatestPurchase(p).price),
            latestPurchaseDate: _this.$options.filters.shortdate(_this.getLatestPurchase(p).date)
          }
        };
      }) : [];
    },
    queryFilters: function queryFilters() {
      return {
        parameters: this.$store.getters["explore/getQueryArray"],
        limit: 10,
        offset: this.$store.getters["explore/getOffset"]
      };
    },
    filters: function filters() {
      var _this2 = this;
      var out = [];
      this.geography.forEach(function (geo) {
        out.push(geo.name);
      });
      this.owners.forEach(function (ownr) {
        out.push(ownr.name);
      });
      this.ownership.forEach(function (ownrship) {
        out.push(_this2.$t(ownrship.name));
      });
      this.usage.forEach(function (usge) {
        out.push(usge.name);
      });
      out.push.apply(out, _toConsumableArray(this.ranges.map(function (x) {
        return x.name;
      })));
      return out;
    },
    geography: function geography() {
      return this.$store.getters["explore/getGeography"];
    },
    owners: function owners() {
      return this.$store.getters["explore/getCvr"];
    },
    ownership: function ownership() {
      return this.$store.getters["explore/getOwnership"];
    },
    usage: function usage() {
      return this.$store.getters["explore/getUsage"];
    },
    ranges: function ranges() {
      var arr = [];
      var def = this.$store.getters["explore/getDefaults"];
      //SITE AREA
      // if user has changed the state of the data
      if (this.$store.getters["explore/getSizeSiteAreaEnabled"]) {
        var site = this.$store.getters["explore/getSizeSiteArea"];
        var name = this.$t("EXPLORE_LIST_ITEM_SITE_AREA");
        // if user has changed the lower handle
        if (site[0] != def.size.siteArea[0]) {
          //if user has changed both handles handle then
          if (site[1] != def.size.siteArea[1]) {
            name += ": ".concat(site[0], " - ").concat(site[1], " m\xB2");
          } else {
            name += ": ".concat(site[0], "+ m\xB2");
          }
        } else {
          //user must have only changed the upper handle then
          name += ": ".concat(this.$t("EXPLORE_CHIPS_BELOW"), " ").concat(site[1], " m\xB2");
        }
        arr.push({
          id: 1,
          name: name,
          close: "clearSizeSiteArea"
        });
      }
      //BUILDING AREA
      if (this.$store.getters["explore/getSizeBuildingAreaEnabled"]) {
        var build = this.$store.getters["explore/getSizeBuildingArea"];
        var _name = this.$t("EXPLORE_LIST_ITEM_BUILDING_AREA");
        if (build[0] != def.size.buildingArea[0]) {
          if (build[1] != def.size.buildingArea[1]) {
            _name += ": ".concat(build[0], " - ").concat(build[1], " m\xB2");
          } else {
            _name += ": ".concat(build[0], "+ m\xB2");
          }
        } else {
          _name += ": ".concat(this.$t("EXPLORE_CHIPS_BELOW"), " ").concat(build[1], " m\xB2");
        }
        arr.push({
          id: 2,
          name: _name,
          close: "clearSizeBuildingArea"
        });
      }
      //NO OF FLOORS
      if (this.$store.getters["explore/getSizeNoOfFloorsEnabled"]) {
        var floor = this.$store.getters["explore/getSizeNoOfFloors"];
        var _name2 = "";
        if (floor[0] != def.size.noOfFloors[0]) {
          if (floor[1] != def.size.noOfFloors[1]) {
            _name2 += "".concat(floor[0], " - ").concat(floor[1]);
          } else {
            _name2 += "".concat(floor[0], "+");
          }
        } else {
          _name2 += "".concat(this.$t("EXPLORE_CHIPS_BELOW"), " ").concat(floor[1]);
        }
        _name2 += " ".concat(this.$t("EXPLORE_CHIPS_FLOORS"));
        arr.push({
          id: 3,
          name: _name2,
          close: "clearSizeNoOfFloors"
        });
      }
      //NO OF UNITS
      if (this.$store.getters["explore/getSizeNoOfUnitsEnabled"]) {
        var unit = this.$store.getters["explore/getSizeNoOfUnits"];
        var _name3 = "";
        if (unit[0] != def.size.noOfUnits[0]) {
          if (unit[1] != def.size.noOfUnits[1]) {
            _name3 += "".concat(unit[0], " - ").concat(unit[1]);
          } else {
            _name3 += "".concat(unit[0], "+");
          }
        } else {
          _name3 += "".concat(this.$t("EXPLORE_CHIPS_BELOW"), " ").concat(unit[1]);
        }
        _name3 += " ".concat(this.$t("EXPLORE_CHIPS_UNITS"));
        arr.push({
          id: 4,
          name: _name3,
          close: "clearSizeNoOfUnits"
        });
      }
      // CONSTRUCTION YEAR
      if (this.$store.getters["explore/getConstructionEnabled"]) {
        var cons = this.$store.getters["explore/getConstruction"];
        var _name4 = "";
        if (cons[0] != def.construction[0]) {
          if (cons[1] != def.construction[1]) {
            _name4 += "".concat(this.$t("EXPLORE_FILTER_CONSTRUCTION_YEAR"), " ").concat(cons[0], " - ").concat(cons[1]);
          } else {
            _name4 += "".concat(this.$t("EXPLORE_FILTER_CONSTRUCTION_AFTER"), " ").concat(cons[0]);
          }
        } else {
          _name4 += "".concat(this.$t("EXPLORE_FILTER_CONSTRUCTION_BEFORE"), " ").concat(cons[1]);
        }
        arr.push({
          id: 5,
          name: _name4,
          close: "clearConstruction"
        });
      }
      return arr;
    },
    loading: function loading() {
      return this.$apollo.queries.results.loading;
    },
    totalResults: function totalResults() {
      var _this$results2;
      return (_this$results2 = this.results) === null || _this$results2 === void 0 || (_this$results2 = _this$results2.pageInfo) === null || _this$results2 === void 0 ? void 0 : _this$results2.totalCount;
    },
    resultsCurrentPage: function resultsCurrentPage() {
      return this.$store.getters["explore/getOffset"] / this.resultsPerPage;
    }
  },
  destroyed: function destroyed() {
    this.$map.then(function (map) {
      map === null || map === void 0 || map.fire("draw.deleteAllDrawings");
    });
  },
  methods: {
    scrollToTop: function scrollToTop() {
      var element = document.querySelectorAll(".container-content-start–bottom–overflow-container")[0];
      if (element) {
        element.scrollTop = 0;
      }
    },
    pageNext: function pageNext() {
      this.$store.commit("explore/setHasSearchedYet", false);
      this.$store.commit("explore/setOffset", (this.resultsCurrentPage + 1) * this.resultsPerPage);
      this.scrollToTop();
    },
    pagePrevious: function pagePrevious() {
      this.$store.commit("explore/setHasSearchedYet", false);
      this.$store.commit("explore/setOffset", (this.resultsCurrentPage - 1) * this.resultsPerPage);
      this.scrollToTop();
    },
    pageChange: function pageChange(n) {
      var _this3 = this;
      clearTimeout(this.pageChangeDebounce);
      this.pageChangeDebounce = setTimeout(function () {
        _this3.$store.commit("explore/setHasSearchedYet", false);
        _this3.$store.commit("explore/setOffset", (n - 1) * _this3.resultsPerPage);
        _this3.scrollToTop();
      }, this.pageChangeDebounceTime);
    },
    propertyClicked: function propertyClicked(property) {
      this.$amplitude.track({
        event_type: "Property lookup",
        event_properties: {
          bfe: property.bfeNumber,
          track: "advanced search"
        }
      });
      this.$router.push({
        name: "explore-property-id",
        params: {
          id: property.bfeNumber
        }
      });
    },
    ownerClicked: function ownerClicked(owner) {
      switch (owner.type) {
        case "COMPANY":
          this.$amplitude.track({
            event_type: "Company lookup",
            event_properties: {
              cvr: owner.cvr,
              track: "advanced search"
            }
          });
          this.$router.push({
            name: "explore-company-id-overview",
            params: {
              id: owner.cvr
            }
          });
          break;
        case "PERSON":
          if (owner.id) {
            trackPersonLookup(owner.id, owner.name, "advanced property search");
            this.$router.push({
              name: "explore-person-id-ownership",
              params: {
                id: owner.id
              }
            });
          }
          break;
        default:
          break;
      }
    },
    getAddress: function getAddress(property) {
      return getPropertyAddress(property);
    },
    getPropertyType: function getPropertyType(property) {
      if (property.condo != null) {
        return this.$t("OVERVIEW_TYPE_CONDO");
      } else if (property.isDividedInCondos && property.plots != null) {
        return this.$t("OVERVIEW_TYPE_PROPERTY_WITH_CONDOS");
      } else if (property.plots != null && !property.isDividedInCondos) {
        return this.$t("OVERVIEW_TYPE_MAIN_PROPERTY");
      } else {
        return this.$t("VALUE_NOT_REGISTERED");
      }
    },
    saveToList: function saveToList(bfeNumber) {
      this.propertiesToSave = [bfeNumber];
      this.showAddToList = true;
    },
    saveAllToList: function saveAllToList() {
      this.propertiesToSave = this.exploreProperties;
      this.showAddToList = true;
    },
    getLatestPurchase: function getLatestPurchase(property) {
      if (property.ownerChanges == null || property.ownerChanges.length == 0) return {
        date: null,
        price: null
      };
      var ownerChangesWithPriceSorted = _toConsumableArray(property.ownerChanges).filter(function (oc) {
        return oc.transactionInfo != null && oc.transactionInfo.totalPurchaseSum != null;
      }).sort(function (a, b) {
        return new Date(b.handoverDate).getTime() - new Date(a.handoverDate).getTime();
      });
      if (ownerChangesWithPriceSorted.length == 0) return {
        date: null,
        price: null
      };
      var latestOwnerChangeWithPrice = ownerChangesWithPriceSorted[0];
      return {
        date: latestOwnerChangeWithPrice.handoverDate,
        price: latestOwnerChangeWithPrice.transactionInfo.totalPurchaseSum
      };
    },
    getTotalArea: function getTotalArea(property) {
      var _property$plots;
      var out = 0;
      property === null || property === void 0 || (_property$plots = property.plots) === null || _property$plots === void 0 || _property$plots.forEach(function (plot) {
        return out += plot.registeredArea;
      });
      return out;
    },
    getTotalBuildingArea: function getTotalBuildingArea(property) {
      var _property$plots2;
      return property === null || property === void 0 || (_property$plots2 = property.plots) === null || _property$plots2 === void 0 ? void 0 : _property$plots2.flatMap(function (plot) {
        return plot.buildings;
      }).filter(function (building) {
        return building != null;
      }).reduce(function (a, b) {
        return b.buildingArea ? a + b.buildingArea : a;
      }, 0);
    },
    getBuildingCount: function getBuildingCount(property) {
      var _property$plots3;
      return property === null || property === void 0 || (_property$plots3 = property.plots) === null || _property$plots3 === void 0 ? void 0 : _property$plots3.flatMap(function (plot) {
        return plot.buildings;
      }).filter(function (building) {
        return building != null;
      }).length;
    },
    getUnitCount: function getUnitCount(property) {
      var _property$plots4;
      return property === null || property === void 0 || (_property$plots4 = property.plots) === null || _property$plots4 === void 0 ? void 0 : _property$plots4.flatMap(function (plot) {
        return plot.buildings;
      }).filter(function (building) {
        return building != null;
      }).flatMap(function (building) {
        return building.units;
      }).filter(function (unit) {
        return unit != null;
      }).length;
    },
    copyToClipboard: function copyToClipboard(labels, data, addressText) {
      copySearchResultsToClipboard(labels, data, addressText);
    }
  }
};