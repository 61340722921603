import { render, staticRenderFns } from "./index.vue?vue&type=template&id=fae8d696"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=fae8d696&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,Chip: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/Chip.vue').default,ChipContainerRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/ChipContainerRow.vue').default,ToggleButtonItem: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/ToggleButton/ToggleButtonItem.vue').default,ToggleButton: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/ToggleButton/ToggleButton.vue').default,SearchResultCardProperty: require('/app/components/explore/SearchResultCardProperty.vue').default,CompositionBackgroundCard: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionBackgroundCard.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default,SearchResultTableCellProperty: require('/app/components/explore/SearchResultTableCellProperty.vue').default,SimpleTable: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTable.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default,EmptyState: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/EmptyState/EmptyState.vue').default,ExploreMapLayer: require('/app/components/map/layers/ExploreMapLayer.vue').default,AddToListDialog: require('/app/components/list/AddToListDialog.vue').default})
